import React from "react";
import NextSeo from "next-seo";
import cookies from 'js-cookie';
import { injectIntl } from "react-intl";
const getConfig = require("next/config").default;
const { publicRuntimeConfig } = getConfig();
const { APP, DESTINATION } = publicRuntimeConfig;

const Seo = (props) => {
  const IsCookieMarketing = cookies.get("IsCookieMarketing") || false
  console.log(`IsCookieMarketing: ${IsCookieMarketing}`)
  if(!IsCookieMarketing){
    return null;
  }
  return (
    <>
      <NextSeo
        config={props.config}
      />
    </>
  );
};

Seo.defaultProps = {

};

export default injectIntl(Seo);
