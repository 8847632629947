import React from "react";
import classNames from "classnames";
import { injectIntl } from "react-intl";
import styles from "./Button.styles.css";

const Button = ({ label, processing, intl, onClick, className, ...rest }) => {
  return (
    <button
      className={classNames(styles.button, className)}
      {...{ onClick, ...rest }}
    >
      {!processing && label}
      {processing && intl.formatMessage({ id: "commons.processing" })}
    </button>
  );
};

Button.defaultProps = {
  label: "",
  processing: false,
  onClick() {},
};

export default injectIntl(Button);
