import React, { forwardRef, useEffect, useState, useImperativeHandle } from "react";
import styles from "./ModalWindow.styles.css";
import { injectIntl } from "react-intl";
import classnames from "classnames";
import Button from "../Button";

const ModalWindow = injectIntl(function (props) {
  //   useEffect(() => {
  //     if (!props.visible) return;
  //     const body = document.getElementsByTagName("body")[0];
  //     body.classList.add("modal-open");
  //     return () => body.classList.remove("modal-open");
  //   }, [props.visible]);

  const _translate = (id) => props.intl.formatMessage({ id });
  const { position } = props;

  if (!props.visible) return null;

  let currentStyle = {};
  if (position.left) {
    currentStyle = {
      width: position.width,
      left: position.left,
      top: position.top + position.height,
    };
  }

  return (
    <div id={styles.modal_container} className={props.className || undefined} onClick={() => {
        props.onClose();
      }}>
      <div style={currentStyle} className={classnames([styles.content], [props.classnameContent || undefined])}>
        {props.title && <div className={styles.title}>{props.title}</div>}
        <div className={classnames([styles.container], [props.classnameContainer || undefined])}>{props.children}</div>
        <div class={styles.options}>
          <button style={styles.closeOption} onClick={props.onClose}>
            <span>Close</span>
          </button>
          <Button
            onClick={() => {
              if (props.onSave) {
                props.onSave();
              }
              props.onClose();
            }}
            label="Save"
          />
        </div>
      </div>
    </div>
  );
});

export default forwardRef(function (props, ref) {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({});

  const [isAutoHide, setAutoHide] = useState(props.autohide || false);

  const isScrollingEvent = (ev) => {
    if(isAutoHide){
      setVisible(false)
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", isScrollingEvent);
    return () => {
      document.removeEventListener("scroll", isScrollingEvent);
    };
  }, []);

  useImperativeHandle(ref, () => ({
    show: (options = {}) => {
      setVisible(true);
      if (options.position) {
        setPosition(options.position);
      }
    },
    isVisible() {
      return visible;
    },
    hide: () => {
      setVisible(false);
      setPosition({});
    },
  }));

  if (!visible) return null;

  return (
    <ModalWindow
      onClose={() => {
        setVisible(false);
      }}
      {...props}
      onClose={() => setVisible(false)}
      onSave={props.onSave}
      visible={visible}
      position={position}
    >
      {props.children}
    </ModalWindow>
  );
});
